import React from "react"
import { navigate } from 'gatsby-link'
import { globalHistory } from '@reach/router'
import { TransitionContext } from '@layouts/TransitionLayout'
import { SmoothScroll } from '@hooks/useSmoothScroll'
const Link = ({
	children,
	to,
	blank,
	scroll,
	onClick,
	...other
}) => {
	let isInternal = /^\/(?!\/)/.test(to)
	let isHash = /^(!?#)/.test(to)

	if (isHash) {
		isInternal = true
	}

	const { location } = globalHistory

	const onClickHandler = (e, transitionState, setTranstionState) => {
		e.stopPropagation()
		e.preventDefault()
		if (transitionState !== 0 ){ return }
		if (location.pathname === to) {
			if (scroll) {
				onClick && onClick()
				SmoothScroll(scroll)
			}
			return
		} else {
			onClick && onClick()
			setTranstionState(1)
			setTimeout(() => {
				navigate(`${to}`)
				if (scroll) {
					setTimeout(() => {
						SmoothScroll(scroll)
					}, 1000)
				}
			}, 500)
		}
	}

	return (
		<TransitionContext.Consumer>
			{({ transitionState, setTranstionState }) => {
				if (isInternal) {
					return (
						<a
							to={to}
							onClick={(e) => {
								onClickHandler(e, transitionState, setTranstionState)
							}}
							{...other}
						>
							{children}
						</a>
					)
				} else {
					return (
						<a href={to} target={blank && "_blank"} {...other}>
							{children}
						</a>
					)
				}
			}}
		</TransitionContext.Consumer>
	)
}
export { Link }