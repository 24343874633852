import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { globalHistory } from '@reach/router'
import { gsap, Power4 } from 'gsap'

import { Link } from "@components/Link"
import { useMenuLink } from "@hooks/useURL"
// import { Svg } from '@components/Svg'

import * as css from "@css/components/blocks/ModalMenu.module.styl"

export default function ModalMenu()
{
    let filColor = ["#000000","#ffffff"]

    const buttonRef = React.useRef()
    const topBorderRef = React.useRef()
    const bottomBorderRef = React.useRef() 
    const menuRef = React.useRef()
    const q = useStaticQuery(graphql`
        query {
            menu : file(relativePath: { eq: "Header__btn__menu.svg" }) { publicURL }
        }
    `)
    const links = useMenuLink()

    const [isOpen, setIsOpen] = React.useState(false)
    const menuHandler = () => {
        setIsOpen(!isOpen)

        if (isOpen ){
            gsap.to(topBorderRef.current,{
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[0],
                rotate: '0deg',
                y: '0',
                transformOrigin: "50% 50%",
            })
            gsap.to(bottomBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[0],
                rotate: '0deg',
                y: '0',
                transformOrigin: "50% 50%",
            })
            gsap.to(menuRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                opacity: 0,
                display: 'none',
            })
        } else {
            gsap.to(topBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                rotate: '45deg',
                y: '6px',
                transformOrigin: "50% 50%",
            })
            gsap.to(bottomBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                rotate: '-45deg',
                y: '-6px',
                transformOrigin: "50% 50%",
            })
            gsap.to(menuRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                opacity: 1,
                display: 'flex',
            })
        }
    }
    let pathname = globalHistory.location.pathname

    return (
        <React.Fragment>
            <button className={css.menuButton} onClick={menuHandler} ref={buttonRef}>
                {/* <img src={q.menu.publicURL} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g id="Header__btn__menu" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <rect ref={topBorderRef} width="40" height="2" x="0" y="13" fill={filColor[0]} />
                        <rect ref={bottomBorderRef} width="40" height="2" x="0" y="25" fill={filColor[0]} />
                    </g>
                </svg>
            </button>
            <nav className={css.menuList} style={{display: 'none' , opacity: 0}} ref={menuRef}>
                <div className={css.inner}>
                    <div className={css.item}>
                        <span className={css.logo}>
                            {/* <LoadingSVG/> */}
                        </span>
                    </div>
                    {links.map(({ label, to, scroll }, i) => {
                        return (
                            <div key={i} className={css.item}>
                                <Link to={to} scroll={scroll} onClick={menuHandler}>{label}</Link>
                            </div>
                        )
                    })}
                </div>
            </nav>
        </React.Fragment>
    )
}
