import React from 'react'
import { gsap } from "gsap"
import { CustomEase } from "gsap/CustomEase"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import browser from 'browser-detect'


gsap.registerPlugin(CustomEase)
gsap.registerPlugin(ScrollToPlugin)
let customEase = CustomEase.create("custom", "M0,0 C0.356,-0.002 0.298,0.608 0.4,0.8 0.506,1 0.764,1 1,1")

// Hook
function SmoothScroll(targetId) {
    if (!targetId) return

    let t = document.getElementById(targetId.replace(/#/g, ''))

    if (t) {
        const y = t.offsetTop
        gsap.to(document.querySelector('html'), {
            duration: 0.6,
            scrollTo: y,
            ease: customEase,
        })
    }
}

export { SmoothScroll }