import React from "react"
import { gsap, Power4 } from 'gsap'

import { Link } from "@components/Link"
// import { Svg } from '@components/Svg'
import BackToTopSvg from '@images/Common__lbl__back-to-top.svg'
import * as css from "@css/components/blocks/BackToTop.module.styl"

export default function BackToTop()
{

    return (
        <div className={css.container}>
            <div className={css.inner}>
                <Link className={css.button} to="/">
                    <BackToTopSvg />
                </Link>
            </div>
        </div>
    )
}
