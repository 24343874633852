import React from "react"
// import { graphql , useStaticQuery} from "gatsby"
import { motion, AnimatePresence } from 'framer-motion'
import { useEventListener } from '@hooks/useEventListener'
import { useHandsumData } from '@hooks/useHandsumData'
// import ThreeJs from '@components/Three/Main.js'
// import Cursor from '@components/Cursor'
import Header from '@components/blocks/Header'
import Footer from "@components/blocks/Footer"
import ModalMenu from "@components/blocks/ModalMenu"
import Loading from "@components/blocks/Loading"
// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
// import { ModalMenuState } from '@status/ModalMenuState'
import { useRecoilState } from "recoil"

import '@css/style.styl'

export const TransitionContext = React.createContext()
export default function TransitionLayout({ children, location }) {
    // const [isOpen, setIsOpen] = useRecoilState(ModalMenuState)
    const [transitionState, setTranstionState] = React.useState(3)
    const key = location.pathname
    const duration = 0.1
    const delay = 0.1

    const params = {
        initial: {
            position: 'relative',
            opacity: 0,
        },
        enter: {
            transition: {
                duration: duration,
                delay: delay,
                when: 'beforeChildren',
            },
            opacity: 1,
        },
        exit: {
            transition: { duration: duration },
            opacity: 0,
        },
    }

    // const { appStore } = useStores()
    // React.useEffect(()=>{
    //     appStore.postsData = q.list.edges
    // },[q.list])
    // const transition = React.useContext(TransitionContext)

    React.useEffect(() => {
        // 0: none, 1:start, 2:loading, 3: complete
        //start with TransitionLink.
        console.log(transitionState)
        //init
        if (transitionState === 1) {
            console.log('[[[ Transition <<< Show Start >>> ]]]')
        } else if (transitionState === 2) {
            console.log('[[[ Transition <<< Loading Start >>> ]]]')
        } else if (transitionState === 3) {
            console.log('[[[ Transition <<< Loading Complete >>> ]]]')
            setTranstionState(4)
        } else if (transitionState === 4){
            console.log('[[[ Transition <<< Hide Start >>> ]]]')
            setTimeout(() => {
                setTranstionState(0)
            }, 1500)
        }
    }, [transitionState])

    //browser back push action
    useEventListener('popstate', () => {
        setTranstionState(1)
    })

    return (
        <TransitionContext.Provider value={{ transitionState: transitionState, setTranstionState: setTranstionState }}>
            <div id="root-container" className="root-container">
                <Loading />
                <Header />
                <ModalMenu />
                <AnimatePresence
                    onExitComplete={() => {
                        if (transitionState === 1) {
                            setTranstionState(2)
                        }
                    }}
                    exitBeforeEnter
                >
                    <motion.div
                        key={key}
                        variants={params}
                        initial="initial"
                        animate="enter"
                        exit="exit"
                        onAnimationStart={() => {
                        }}
                        onAnimationComplete={() => {
                            if (transitionState === 2) {
                                setTranstionState(3)
                            }
                        }}
                    >
                        {children}
                        <Footer />
                    </motion.div>
                </AnimatePresence>
            </div>
        </TransitionContext.Provider>
    )
}
