import React from 'react'

// Hook
const switchingWidth = 767
const Sp = ({children}) => {
    return(
        <div className="sp">
            {children}
        </div>
    )
}
const Pc = ({children}) => {
    return(
        <div className="pc">
            {children}
        </div>
    )
}

export { Pc, Sp}