// import React from 'react';
// import { Provider} from 'mobx-react'
// import Store from "../src/stores"

// export default({element})=><Provider store={Store}>{element}</Provider>


import React from 'react';
// import { Provider } from 'mobx-react'
// import Store from "../src/stores"

export default ({ element }) => <div>{element}</div>