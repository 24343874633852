import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"
// import { FluidImage } from "@components/Image"
import { globalHistory } from '@reach/router'
// import { useObserver } from "mobx-react-lite"
import { Pc, Sp } from '@hooks/useMediaQuery'
import { useMenuLink, useFooterLink, useGetLink } from '@hooks/useURL'
import { Svg } from "@components/Svg"
import BackToTop from "@components/blocks/BackToTop"

import * as css from "@css/components/blocks/Footer.module.styl"
import { Back } from "gsap"

export default function Footer()
{
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "Footer__logo__be-strong.svg" }) { publicURL }
            facebook : file(relativePath: { eq: "Common__icn__facebook.svg" }) { publicURL }
        }
    `)

    const { location } = globalHistory
   
    return(
        <>
        { location.pathname !== "/" &&
            <BackToTop/>
        }
        <footer className={css.container}>
            <div className={css.inner}>
                <div className={css.col1}>
                    <div className={css.logo}>
                        <img src={q.logo.publicURL}/>
                    </div>
                    <Menu/>
                </div>
                <Sp>
                    <div className={css.sns}>
                        <Link className={css.item} to="https://www.facebook.com/Be-strong-109082494354700/?modal=admin_todo_tour&notif_id=1605584258965944&notif_t=page_invite&ref=notif" blank>
                            <img src={q.facebook.publicURL} />
                        </Link>
                    </div>
                </Sp>
                <div className={css.col2}>
                    <div className={css.info}>
                        <p className={css.company}>株式会社BE-STRONG / ベストロング</p>
                        <p className={css.address}>〒039-1121 青森県八戸市卸センター2-6-25</p>
                        <p className={css.tel}>TEL.0178-28-2603</p>
                        <p className={css.fax}>FAX.0178-28-2607</p>
                        <p className={css.links}>
                            <Link className={css.item} to='/' scroll="#contact">お問い合わせ</Link>
                            <span className={css.item}>/</span>
                            <Link className={css.item} to='/privacy-policy/'>プライバシーポリシー</Link>
                        </p>
                        <p className={css.copyright}>
                            Copyright © BE-STRONG CO.,LTD. All Rights Reserved.
                        </p>
                    </div>
                </div>
                <div className={css.col3}>
                    <Pc>
                        <div className={css.sns}>
                            <Link className={css.item} to="https://www.facebook.com/Be-strong-109082494354700/?modal=admin_todo_tour&notif_id=1605584258965944&notif_t=page_invite&ref=notif" blank>
                                <img src={q.facebook.publicURL}/>
                            </Link>
                        </div>
                    </Pc>
                </div>
            </div>
        </footer>
        </>
    )
}

function Menu() {
    const menuLinks = useMenuLink()
    return (
        <nav className={css.menu}>
            <Link className={css.item} to="/" scroll="#root-container">TOP</Link>
            { menuLinks.map(({ label, to, scroll }, i) => {
                return (
                    <Link key={i} className={css.item} to={to} scroll={scroll}>{label}</Link>
                )
            })}
        </nav>
    )
}
