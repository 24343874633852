import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "@components/Link"

// import { useObserver } from "mobx-react-lite"
// import { useStores } from '@hooks/useStores'
import { useMenuLink } from '@hooks/useURL'

import * as css from "@css/components/blocks/Header.module.styl"
// import LogoSvg from "@images/Header__logo__be-strong.svg"

export default function Header()
{
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "Header__logo__be-strong.svg" }) { publicURL }
        }
    `)

    return(
        <header className={css.container}>
            <div className={css.inner}>
                <h1 className={css.left}>
                    <Link className={css.logo} to="/" scroll="#root-container">
                        {/* <LogoSvg viewBox="0 0 215 39"/> */}
                        <img src={q.logo.publicURL} alt="BE-STRONG"/>
                    </Link>
                </h1>
                <div className={css.right}>
                    <Menu/>
                </div>
            </div>
        </header>
    )
}

function Menu(){
    const menuLinks = useMenuLink()
    return(
        <nav className={css.menu}>
            {
                menuLinks.map( ({label,to, isButton, scroll},i) => {
                    return(
                        <span key={i} className={`${ !isButton ? css.item : css.item_button }`}>
                            <Link to={to} scroll={scroll}>{label}</Link>
                        </span>
                    )
                })
            }
        </nav>
    )
}
