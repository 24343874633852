module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-image@1.13.0_gatsby-plugin-sharp@3.1_fpkafrzameoag7vkto4voamsm4/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@3.15.0_gatsby@3.15.0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@5.13.0_gatsby-plugin-sharp@3.13.0_gatsby-transformer-sharp@3.13.0_gatsby@3.15.0/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"perPage":20,"requestConcurrency":3,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"url":" https://api.be-strong.co.jp/wp/graphql","verbose":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-web-font-loader@1.0.4/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Noto Sans JP:500,900"]}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@4.10.0_gatsby@3.15.0_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":100,"icon":false,"className":"anchor-","maintainCase":true,"removeAccents":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@5.10.0_gatsby-plugin-sharp@3.13.0_gatsby@3.15.0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080,"linkImagesToOriginal":false,"quality":100,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-breadcrumb@12.1.1_gatsby@3.15.0/node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.13.0_gatsby@3.15.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"be-strong.co.jp","short_name":"be-strong.co.jp","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/assets/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0bafba5e9267f6f0cf3111c7f21ec153"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
