// extracted by mini-css-extract-plugin
export var address = "B_by";
export var animationMarquee = "B_c";
export var col1 = "B_bt";
export var col2 = "B_bv";
export var col3 = "B_bw";
export var company = "B_s";
export var container = "B_b";
export var copyright = "B_bC";
export var fax = "B_bB";
export var info = "B_bx";
export var inner = "B_h";
export var item = "B_g";
export var links = "B_n";
export var logo = "B_bq";
export var menu = "B_br";
export var sns = "B_bD";
export var tel = "B_bz";