import React from 'react'

// Hook
const data = [
    // {
    //     label: 'ABOUT US',
    //     to: '/aboutus/',
    // },
    {
        label: 'COMPANY',
        to: '/company/',
    },
    // {
    //     label: 'DEVELOPMENT',
    //     to: '/development/',
    // },
    {
        label: 'INFORMATION',
        to: '/information/',
    },
    {
        label: 'CONTACT',
        to: '/',
        isButton: true,
        scroll: '#contact',
    }
]
const useGetLink = (key) => {
    let value = null
    data.map(({ label, to }) => {
        if (label === key) {
            value = to
        }
    })
    return value
}

const useMenuLink = () => {
    return data
}

const useFooterLink = () => {
    return [
        {
            label: 'ISO',
            to: '/iso'
        },
        {
            label: 'プライバシーポリシー',
            to: '/privacy-policy'
        }
   ]
}

export { useMenuLink, useFooterLink, useGetLink }